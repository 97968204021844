<template>
    <b-modal id="add-new-diagnostic-image-category-sidebar" :visible="isEditReviewSidebarActive" size="xl"
        @hidden="resetForm" title="Edit Membership parameter" hide-footer
        @change="(val) => $emit('update:isEditReviewSidebarActive', val)">
        <template #default="{ hide }">
            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <validation-provider #default="validationContext" name="name" rules="required">
                        <b-form-group label="Name" label-for="name">
                            <b-form-input id="name" v-model="stateData.name" autofocus
                                :state="getValidationState(validationContext)" trim
                                placeholder="Type membership parameter name" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="validity" rules="required|numeric|max_value:12">
                        <b-form-group label="Validity" label-for="validity">
                            <b-form-input id="validity" v-model="stateData.validity"
                                :state="getValidationState(validationContext)" trim type="number"
                                placeholder="Type membership parameter validity" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="price" rules="required">
                        <b-form-group label="Price" label-for="price">
                            <b-form-input id="price" v-model="stateData.price"
                                :state="getValidationState(validationContext)" trim type="number"
                                placeholder="Type membership parameter price" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="facility" rules="required">
                        <b-form-group label="Facility" label-for="facility">
                            <treeselect id="facility" v-model="facilities" :options="facilityOptions" :multiple="true"
                                :clearable="true" :normalizer="normalizer" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="Status" rules="required">
                        <b-form-group :label="$t('Form.Status')" label-for="status">
                            <treeselect id="status" v-model="stateData.status"
                                :state="getValidationState(validationContext)" :options="optionsStatus"
                                :normalizer="normalizer" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <b-row v-for="(parameter, index) in parameters" :key="index">
                        <b-col>
                            <validation-provider #default="validationContext" name="parameter type" rules="required">
                                <b-form-group label="parameter type" label-for="parameter type">
                                    <treeselect v-model="parameter.type" :async="true" :options="options"
                                        :defaultOptions="true" :load-options="searchmemberships" search-nested
                                        :disable-branch-nodes="true" valueFormat="object" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col>
                            <validation-provider #default="validationContext" name="count" rules="required">
                                <b-form-group label="Count" label-for="count">
                                    <b-form-input v-model="parameter.count" :state="getValidationState(validationContext)"
                                        trim type="number" placeholder="Type membership count" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col>
                            <validation-provider #default="validationContext" name="Activated at" rules="required">
                                <b-form-group label="Activated at" label-for="activated_at">
                                    <treeselect id="parameter" v-model="parameter.activated_at" :options="months"
                                        :disabled="stateData.validity == null || stateData.validity == undefined" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col align-self="center" cols="auto">
                            <b-button style="border-radius: 50px;" variant="outline-danger"
                                @click="parameters.splice(index, 1)" v-if="index != 0" class="mr-1">
                                x
                            </b-button>
                            <b-button style="border-radius: 50px" variant="outline-primary"
                                @click="parameters.push({ memberships_parameters_types_id: null, count: null, activated_at: null })"
                                v-if="(parameters.length - 1) == index">
                                +
                            </b-button>
                        </b-col>
                    </b-row>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                            <span v-if="!loading">Edit</span>
                            <span v-else>
                                <SpinnerLoading />
                            </span>
                        </b-button>
                        <b-button type="button" variant="outline-danger" @click="hide">
                            {{ $t("Cancel") }}
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-modal>
</template>

<script>
import {
    BSidebar,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
} from "bootstrap-vue";
import formValidation from "@/core/utils/forms/form-validation";
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import { required } from "@validations";
import { ref, watch } from "@vue/composition-api";

import axiosCT from "@/core/services/apiInsurance/admin/consults/memberships";

export default {
    components: {
        BSidebar,
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        SpinnerLoading,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: "isEditReviewSidebarActive",
        event: "update:is-edit-system-review-sidebar-active",
    },
    props: {
        isEditReviewSidebarActive: {
            type: Boolean,
            required: true,
        },
        itemEdit: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        return {
            required,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
    setup(props, { emit }) {
        const optionsStatus = [
            { name: "Active", id: 1 },
            { name: "Inactive", id: 0 },
        ];

        const labos = ref([])
        const cat_lab = ref([])
        const options = ref([])
        const procedures = ref([])
        const sonog = ref([])
        const tests = ref([])
        const treats = ref([])

        let months = ref([])

        const facilityOptions = JSON.parse(atob(localStorage.getItem("setFacilityOptions")));

        const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit.value)));

        const parameters = ref(stateData.value.parameters_details.length ? [] : { type: null, count: null, activated_at: null });

        const facilities = ref(stateData.value.facilities)

        const getNewChildrens = ({ array, subChildren, parentId }) => {
            const newChildrens = []
            array.forEach((child) => {
                if (subChildren) {
                    const newSubChildrens = []
                    child[subChildren].forEach(subChild => {
                        newSubChildrens.push({
                            id: `${subChild.id} - ${subChild.name} - ${parentId}`,
                            label: subChild.price ? `${subChild.name} - ${subChild.price}` : subChild.name,
                            trueId: subChild.id,
                            fatherId: parentId
                        })
                    });
                    newChildrens.push({
                        id: `${child.id} - ${child.name} - ${parentId}`,
                        label: `${child.name}`,
                        children: newSubChildrens,
                        trueId: child.id,
                        fatherId: parentId
                    })
                } else {
                    newChildrens.push({
                        id: `${child.id} - ${child.name} - ${parentId}`,
                        label: child.price ? `${child.name} - ${child.price}` : child.name,
                        trueId: child.id,
                        fatherId: parentId
                    })
                }
            })
            return newChildrens
        }

        const resetuserData = () => {
            emit("editReviewSuccess", false);
            stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
        };

        const searchmemberships = ({ action, searchQuery, callback, parentNode }) => {
            if (action === "ASYNC_SEARCH") {
                // const datos = {
                //     name: searchQuery,
                // };
                axiosCT.typeParameterSearch().then(({ data: { data } }) => {
                    data = data.map((res) => {
                        if (res.identifier == "consultations") {
                            return {
                                id: res.id,
                                label: res.name,
                                identifier: res.identifier,
                            }
                        }
                        return {
                            id: res.id,
                            label: res.name,
                            identifier: res.identifier,
                            children: null
                        }
                    })
                    options.value = data
                    callback(null, data);
                });
            }

            if (action === LOAD_CHILDREN_OPTIONS) {
                try {
                    switch (parentNode.identifier) {
                        case "procedures":
                            parentNode.children = getNewChildrens({ array: procedures.value, parentId: parentNode.id })
                            callback();
                            break;
                        case "laboratories":
                            parentNode.children = getNewChildrens({ array: labos.value, parentId: parentNode.id, subChildren: "laboratories" })
                            callback();
                            break;
                        case "laboratories_pack":
                            parentNode.children = getNewChildrens({ array: cat_lab.value, parentId: parentNode.id })
                            callback();
                            break;
                        case "test":
                            parentNode.children = getNewChildrens({ array: tests.value, parentId: parentNode.id })
                            callback();
                            break;
                        case "diagnostic_imagings":
                            parentNode.children = getNewChildrens({ array: sonog.value, parentId: parentNode.id, subChildren: "diagnostic_imagings" })
                            callback();
                            break;
                        case "treatments":
                            parentNode.children = getNewChildrens({ array: treats.value, parentId: parentNode.id })
                            callback();
                            break;
                        default:
                            callback(new Error('Children options not found!'))
                            break;
                    }
                } catch (error) {
                    callback(new Error('Children options not found!'))
                }
            }
        }

        const getMptParametrizables = (page = 1) => {
            axiosCT.mptParametrizables(page)
                .then(({ category_lab, procedures: { data: procedure_data }, sonograms, tests: { data: test_data }, treatments }) => {
                    labos.value = category_lab
                    const newCatLab = category_lab.filter(labo => {
                        if(labo.price) return labo
                    })
                    cat_lab.value = newCatLab
                    procedures.value = procedure_data
                    sonog.value = sonograms
                    tests.value = test_data
                    treats.value = treatments
                })
        }

        getMptParametrizables()

        const loading = ref(false);

        const checkParametrizable = (parameter) => {
            if (!parameter.parametrizable) return ""
            if (!parameter.parametrizable.price) return parameter.parametrizable.name
            return `${parameter.parametrizable.name} - ${parameter.parametrizable.price}`
        }

        stateData.value.parameters_details.forEach(parameter => {
            if (parameter.memberships_parameters_types_id == 1) {
                parameters.value.push({
                    type: {
                        id: parameter.memberships_parameters_types_id,
                        label: parameter.memberships_parameters_types.name,
                    },
                    count: parameter.count,
                    activated_at: parameter.activated_at
                })
            } else {
                const name = checkParametrizable(parameter)
                parameters.value.push({
                    type: {
                        id: `${parameter.parametrizable_id} - ${name}`,
                        label: name,
                        trueId: parameter.parametrizable_id,
                        fatherId: parameter.memberships_parameters_types_id
                    },
                    count: parameter.count,
                    activated_at: parameter.activated_at
                })
            }
        });

        checkMonths()

        watch(() => stateData.value, (newVal) => {
            if (newVal.validity != null || newVal.validity != undefined) {
                checkMonths()
            }
        }, { deep: true })
        function checkMonths() {
            if (stateData.value.validity > 12) return
            const newMonths = []
            for (let i = 0; i < stateData.value.validity; i++) {
                newMonths.push({ id: `${i}`, label: `${i}` })
            }
            months.value = newMonths
        }

        const onSubmit = () => {
            loading.value = true;
            const newParameters = parameters.value.map(({ count, activated_at, type: { id, trueId, fatherId } }) => {
                return {
                    count,
                    activated_at,
                    parametrizable_id: trueId,
                    memberships_parameters_types_id: id === 1 ? id : fatherId
                }
            })
            const data = {
                ...stateData.value,
                parameters_details: newParameters,
                facilities: facilities.value,
            };
            axiosCT.membershipParameterUpdate(stateData.value.id, data).then(() => {
                loading.value = false;
                resetuserData();
                emit("editmembershipSuccess", true);
            }).catch(() => {
                emit("editmembershipSuccess", false);
                loading.value = false;
            });
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(
            resetuserData
        );
        return {
            facilities,
            parameters,
            stateData,
            loading,
            optionsStatus,
            options,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            searchmemberships,
            facilityOptions,
            months
        };
    },
};
</script>

<style></style>
